import React from "react"
import { graphql, Link } from "gatsby"
import get from "lodash/get"
import find from "lodash/find"
import result from "lodash/result"
import map from "lodash/map"
import dropWhile from "lodash/dropWhile"

import Layout from "./layouts"
import Carousel from "../components/carousel"
import Category from "../components/category"
import News from "../components/news"
import LayoutCopy from "../components/layoutCopy"
import LayoutImages from "../components/layoutImages"
import Catact from "../components/contact"
import SEO from "../components/seo"
import MultipleCarousel from "../components/multipleCarousel"

const IndexPage = props => {
  let contentModules = get(props, "data.homeJson.contentModules", [])
  const allCategories = get(props, "pageContext.allCategories", [])
  const products = get(props, "data.productsJson.edges", [])
  const sliders = result(
    find(contentModules, item => item.title === "Home > Banner"),
    "images"
  )
  const items = map(sliders, item => ({
    src: get(item, "backgroundImage.fluid", ""),
    altText: get(item, "backgroundImage.title", ""),
    caption: get(item, "backgroundImage.title", ""),
  }))

  contentModules = dropWhile(
    contentModules,
    item => item.title === "Home > Banner"
  )

  return (
    <Layout>
      <SEO title="首页" />
      <div className="hr_20" />
      <div className="slider">
        <Carousel items={items} />
        <div className="shadow shadow_left pull-left" />
        <div className="shadow shadow_right pull-right" />
      </div>
      <div className="hr_30" />
      <div className="col-lg-12 bg-white index-con">
        <div className="hr_25" />
        <div className="col-lg-3">
          {allCategories && <Category allCategories={allCategories} />}
          <News />
          <Catact />
        </div>
        <div className="col-lg-9">
          {map(contentModules, (item, index) => {
            switch (item.__typename) {
              case "ContentfulLayoutCopy":
                return <LayoutCopy {...item} key={`copy-${index}`} />
              case "ContentfulLayoutImagesListings":
                return <LayoutImages {...item} key={`image-${index}`} />
            }
          })}
          <div className="index-con03">
            <div className="tit">
              <h4 className="pull-left">
                <i className="icon-beaker"></i> &nbsp;主打产品
              </h4>
              <h5 className="pull-right text-right">
                <div className="hr_5"></div>
                <Link to="/products" className="more">
                  <i className="icon-plus"></i> 更多产品
                </Link>
              </h5>
              <div className="clearfix"></div>
            </div>
            <div className="hr_20"></div>
            <MultipleCarousel items={products} />
          </div>
          <div className="hr_25"></div>
        </div>
      </div>
      <div className="hr_30" />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomeQuery {
    homeJson: contentfulLayout {
      title
      contentModules {
        ... on ContentfulLayoutImagesListings {
          images {
            backgroundImage {
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
              title
            }
          }
          title
        }
        ... on ContentfulLayoutCopy {
          ctaTitle
          ctaLink
          title
          headline
          visualStyle
          copy {
            copy
          }
        }
      }
    }
    productsJson: allContentfulProduct(limit: 8) {
      edges {
        node {
          slug
          thumbnail {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          createdAt(formatString: "MMMM Do, YYYY")
          productName {
            productName
          }
          description {
            description
          }
          categories {
            title {
              title
            }
            slug
          }
        }
      }
    }
  }
`
