import React from "react"
import PropTypes from "prop-types"
import map from "lodash/map"
import get from "lodash/get"
import Img from "gatsby-image"

const LayoutImages = ({ images }) => {
  return (
    <div>
      {map(images, (item, index) => (
        <div className="col-lg-4" key={index}>
          <Img fluid={get(item, "backgroundImage.fluid", "")} />
          <div className="hr_20"></div>
        </div>
      ))}
      <div className="clearfix"></div>
      <div className="hr_20"></div>
    </div>
  )
}

LayoutImages.propTypes = {
  images: PropTypes.array,
}

export default LayoutImages
