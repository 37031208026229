import React from "react"
import Img from "gatsby-image"
import Slider from "react-slick"
import PropTypes from "prop-types"
import map from "lodash/map"
import get from "lodash/get"

const Carousel = ({ items }) => {
  const settings = {
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    swipeToSlide: true,
    className: "pic-scroll-list",
  }
  return (
    <div>
      <Slider {...settings}>
        {map(items, (item, index) => (
          <div key={index}>
            <li className="slide">
              <Img className="thumb" alt="" fluid={get(item, "node.thumbnail.fluid")} />
              <p>{get(item, "node.productName.productName", "")}</p>
            </li>
          </div>
        ))}
      </Slider> 
    </div>
  )
}

Carousel.propTypes = {
  items: PropTypes.array,
}

Carousel.defaultProps = {
  items: [],
}

export default Carousel
