import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import { Link } from "gatsby"

const LayoutCopy = ({ ctaTitle, ctaLink, headline, copy }) => {
  return (
    <div className="index-con01">
      <div className="tit">
        <h4 className="pull-left">{headline}</h4>
        <h5 className="pull-right text-right">
          <div className="hr_5"></div>
          <Link to={ctaLink} className="more">
            {ctaTitle}
          </Link>
        </h5>
        <div className="clearfix"></div>
      </div>
      <div className="hr_20"></div>
      <p>{get(copy, "copy", "")}</p>
    </div>
  )
}

LayoutCopy.propTypes = {
  ctaTitle: PropTypes.string,
  ctaLink: PropTypes.string,
  headline: PropTypes.string,
  copy: PropTypes.object,
}

export default LayoutCopy
